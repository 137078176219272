/* eslint-disable react/display-name */
import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useLocalization } from 'gatsby-theme-i18n';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import {
  BioTemplateQuery,
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
} from '../../graphql-types';
import { WEBSITE } from '../types/website.enum';
import edgepointTheme from '../themes/edgepoint';
import cymbriaTheme from '../themes/cymbria';
import Layout from '../components/Layout';
import { PageContext } from '../types/pageContext';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/SEO';
import { useGlobalState } from '../hooks/useGlobalState';
import ContentfulImage from '../components/ContentfulImage';
import Typography from '../components/Typography';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import Link from '../components/Link';
import { ChevronLeft } from '@material-ui/icons';
import { getTranslation } from '../services/translation.service';
import { getDateWithLocaleOffset } from '../services/date.service';
import { getPlainTextFromMicrocopy } from '../utils/getPlainTextFromMicrocopy';
import CookiePreferences from '../components/CookiePreferences';

interface ITenureLabels {
  cymTenureLengthTitle?: string;
  cymTenureYearText?: string;
  epwTenureLengthTitle?: string;
  epwTenureYearText?: string;
}

const teamContentfulRichTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong className="font-medium">{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="mt-s4 first:mt-0">{children}</p>
    ),
  },
};

const getYearsBetween = (startYYYYMMDD: string): number => {
  const localizedStartDate = getDateWithLocaleOffset(startYYYYMMDD);
  const localizedEndDate = getDateWithLocaleOffset();

  const yearsBetween =
    (localizedEndDate.getTime() - localizedStartDate.getTime()) /
    (1000 * 3600 * 24 * 365);

  return round(yearsBetween, 1);
};

const round = (value: number, precision: number): number => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const query = graphql`
  query BioTemplate($id: String!, $node_locale: String!) {
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      __typename
      slug
      seo {
        ...ContentfulComposeSeoFragment
      }
      content {
        ... on ContentfulTopicBio {
          id
          contentful_id
          name
          __typename
          teams {
            id
          }
          position
          phone
          lastName
          firstName
          emailAddress
          about {
            raw
          }
          image {
            ...AssetFragment
            gatsbyImageData(width: 300, placeholder: BLURRED)
          }
          dateJoined
        }
      }
      parentPage {
        slug
      }
      website {
        ...WebsiteFragment
      }
      pageHeader {
        ...ContentfulComponentSiteHeaderFragment
      }
      pageFooter {
        ...ContentfulComponentSiteFooterFragment
      }
    }
    allContentfulGlobalMicrocopy(
      filter: {
        node_locale: { eq: $node_locale }
        key: {
          in: [
            "epwTenureLengthTitle"
            "cymTenureLengthTitle"
            "epwTenureYearText"
            "cymTenureYearText"
          ]
        }
      }
    ) {
      edges {
        node {
          key
          name
          node_locale
          id
          copy {
            raw
          }
        }
      }
    }
  }
`;

const BioPageTemplate: React.FC<PageProps<BioTemplateQuery, PageContext>> = (
  props,
) => {
  const { data, pageContext, location } = props;
  const { contentfulComposePage, allContentfulGlobalMicrocopy } = data;
  const { parentPage, content: bio } = contentfulComposePage;
  const { edges: microcopy } = allContentfulGlobalMicrocopy;
  const { locale } = useLocalization();
  const { site } = useGlobalState();
  const backToString = getTranslation('BackTo', locale);

  const yearsBetween = getYearsBetween(bio?.dateJoined);

  const labels: ITenureLabels = {};
  for (const micro of microcopy) {
    labels[micro?.node?.key] = getPlainTextFromMicrocopy(micro.node.copy?.raw);
  }

  const tenureTitle =
    site === WEBSITE.EDGEPOINT
      ? labels.epwTenureLengthTitle
      : labels.cymTenureLengthTitle;
  const tenureYearText =
    site === WEBSITE.EDGEPOINT
      ? labels.epwTenureYearText
      : labels.cymTenureYearText;

  const mobileMenuPaddingClasses = 'pt-m4 lg:pt-0';

  const cookiePreferences = contentfulComposePage?.website?.cookiePreferences;

  const pageHeader =
    (contentfulComposePage?.pageHeader as unknown as ContentfulComponentSiteHeader) ??
    (contentfulComposePage?.website
      ?.header as unknown as ContentfulComponentSiteHeader);

  const pageFooter =
    (contentfulComposePage?.pageFooter as unknown as ContentfulComponentSiteFooter) ??
    (contentfulComposePage?.website
      ?.footer as unknown as ContentfulComponentSiteFooter);

  return (
    <Layout theme={site === WEBSITE.EDGEPOINT ? edgepointTheme : cymbriaTheme}>
      <SEO data={contentfulComposePage?.seo} location={location} />
      <Header
        data={pageHeader}
        location={location}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        mobileLogo={contentfulComposePage?.website?.siteAssets?.mobileLogo}
      />
      <main id="main" className={`${mobileMenuPaddingClasses}`}>
        <div className="container wide lg:mt-s3 pb-m2">
          {parentPage && (
            <div className="">
              <Link
                language={locale}
                link={{
                  page: parentPage,
                }}
                className="inline-block"
              >
                <Typography
                  as="div"
                  variant="footerBody3"
                  className="inline-flex items-center"
                >
                  <ChevronLeft className="text-secondary ml-[-8px]" />
                  {backToString} {pageContext?.parentPage?.title}
                </Typography>
              </Link>
            </div>
          )}

          <div className="mt-s2 lg:mt-m2 lg:grid lg:gap-x-s3 lg:grid-cols-12">
            <div className="lg:col-span-3">
              {bio.image && (
                <ContentfulImage
                  image={bio.image}
                  alt={
                    bio.image?.description ||
                    `Picture of ${bio.firstName} ${bio.lastName}`
                  }
                  style={{
                    width: '100%',
                  }}
                  objectPosition="center top"
                  objectFit="contain"
                />
              )}
              {(bio.firstName || bio.lastName || bio.position) && (
                <div className="mt-s1">
                  {bio.firstName && bio.lastName && (
                    <Typography as="div" variant="body" className="font-medium">
                      {bio.firstName} {bio.lastName}
                    </Typography>
                  )}
                  {bio.position && (
                    <Typography as="div" variant="body" className="text-sm">
                      {bio.position}
                    </Typography>
                  )}
                </div>
              )}
              {(bio.phone || bio.emailAddress) && (
                <div className="mt-s3">
                  {bio.phone && (
                    <Typography
                      as="div"
                      variant="body"
                      className="mt-s1 text-sm"
                    >
                      <a
                        href={`tel:${bio.phone}`}
                        className="flex items-center"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.9669 11.5667C14.8037 11.3929 14.2278 10.8792 13.169 10.1995C12.1025 9.51386 11.3159 9.08636 11.0903 8.98667C11.0704 8.97785 11.0485 8.97462 11.0269 8.97732C11.0053 8.98002 10.9849 8.98854 10.9678 9.00199C10.6044 9.28542 9.99248 9.80605 9.95966 9.83417C9.74779 10.0157 9.74779 10.0157 9.57435 9.95917C9.26935 9.85917 8.32185 9.35605 7.49623 8.52855C6.6706 7.70105 6.14154 6.73011 6.04154 6.42542C5.98435 6.25167 5.98435 6.25167 6.16654 6.0398C6.19466 6.00699 6.7156 5.39511 6.99904 5.03199C7.01248 5.01489 7.02101 4.99445 7.02371 4.97286C7.02641 4.95128 7.02317 4.92937 7.01435 4.90949C6.91466 4.68355 6.48716 3.8973 5.80154 2.83074C5.12091 1.7723 4.60779 1.19636 4.43404 1.03324C4.41809 1.01819 4.39839 1.00772 4.37699 1.00294C4.3556 0.998153 4.33331 0.999228 4.31248 1.00605C3.70512 1.21476 3.11912 1.48108 2.56248 1.80136C2.02511 2.11374 1.51636 2.47295 1.04216 2.8748C1.02561 2.88887 1.0132 2.90718 1.00626 2.92777C0.999327 2.94836 0.998126 2.97045 1.00279 2.99167C1.0681 3.29605 1.38029 4.56667 2.34904 6.32667C3.33748 8.12292 4.02248 9.04324 5.47404 10.4898C6.9256 11.9364 7.87498 12.6626 9.6731 13.651C11.4331 14.6198 12.7044 14.9323 13.0081 14.997C13.0294 15.0016 13.0515 15.0004 13.0721 14.9935C13.0928 14.9865 13.1111 14.9741 13.1253 14.9576C13.5271 14.4834 13.8862 13.9747 14.1984 13.4373C14.5186 12.8806 14.7849 12.2946 14.9937 11.6873C15.0004 11.6666 15.0014 11.6445 14.9967 11.6233C14.992 11.6021 14.9817 11.5826 14.9669 11.5667Z"
                            fill="black"
                          />
                        </svg>
                        <span className="ml-s1">{bio.phone}</span>
                      </a>
                    </Typography>
                  )}
                  {bio.emailAddress && (
                    <Typography
                      as="div"
                      variant="body"
                      className="mt-s1 text-sm"
                    >
                      <a
                        href={`mailto:${bio.emailAddress}`}
                        className="flex items-center"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 2.5H1.5C1.36739 2.5 1.24021 2.55268 1.14645 2.64645C1.05268 2.74021 1 2.86739 1 3V13C1 13.1326 1.05268 13.2598 1.14645 13.3536C1.24021 13.4473 1.36739 13.5 1.5 13.5H14.5C14.6326 13.5 14.7598 13.4473 14.8536 13.3536C14.9473 13.2598 15 13.1326 15 13V3C15 2.86739 14.9473 2.74021 14.8536 2.64645C14.7598 2.55268 14.6326 2.5 14.5 2.5ZM8.30688 8.89469C8.21912 8.9629 8.11115 8.99994 8 8.99994C7.88885 8.99994 7.78088 8.9629 7.69312 8.89469L2.79844 5.08781L3.41219 4.29844L8 7.86656L12.5878 4.29844L13.2016 5.08781L8.30688 8.89469Z"
                            fill="black"
                          />
                        </svg>
                        <span className="ml-s1">{bio.emailAddress}</span>
                      </a>
                    </Typography>
                  )}
                </div>
              )}
            </div>
            <div
              className={`mt-m2 md:mt-0 ${
                bio.dateJoined ? `md:col-span-6` : `md:col-span-7`
              }`}
            >
              {bio.about &&
                renderContentfulRichText(
                  bio.about,
                  teamContentfulRichTextOptions,
                )}
            </div>
            {bio.dateJoined && yearsBetween && (
              <div className="lg:col-span-3 mt-m2 lg:mt-0">
                <div className="bg-greyLight rounded-[1.5rem] text-center p-6">
                  {tenureTitle && (
                    <Typography
                      as="div"
                      variant="body"
                      className="font-medium text-2xl"
                    >
                      {tenureTitle}
                    </Typography>
                  )}
                  <Typography
                    as="div"
                    variant="body"
                    className="font-semibold w-full align-middle text-[5rem] leading-snug"
                  >
                    {yearsBetween.toLocaleString(locale, {
                      maximumFractionDigits: 1,
                    })}
                  </Typography>
                  {tenureYearText && (
                    <Typography
                      as="div"
                      variant="body"
                      className="font-medium text-2xl"
                    >
                      {tenureYearText}
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer
        data={pageFooter}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        socialLinks={contentfulComposePage?.website?.siteAssets?.socialLinks}
      />

      {cookiePreferences && (
        <CookiePreferences
          id={cookiePreferences?.id}
          title={cookiePreferences?.title}
          description={cookiePreferences?.description}
          manageConsentPreferencesTitle={
            cookiePreferences?.manageConsentPreferencesTitle
          }
          cookieDuration={cookiePreferences?.cookieDuration}
          cookies={cookiePreferences?.cookies}
          acceptAllCookiesButton={cookiePreferences?.acceptAllCookiesButton}
          rejectAllCookiesButton={cookiePreferences?.rejectAllCookiesButton}
          manageCookiePreferencesButton={
            cookiePreferences?.manageCookiePreferencesButton
          }
          acceptChoosenPreferencesButton={
            cookiePreferences?.acceptChoosenPreferencesButton
          }
          location={location}
        />
      )}
    </Layout>
  );
};

export default BioPageTemplate;
